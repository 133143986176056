import { Locale } from "../../lib/types"

export const GET_GIFT_CARDS_REQUEST = "GIFT_CARDS/getRequest"
export const GET_GIFT_CARDS_SUCCESS = "GIFT_CARDS/getSuccess"
export const GET_GIFT_CARDS_FAILED = "GIFT_CARDS/getFailed"

export const getGiftCardsRequest = (locale: Locale) => ({
  type: GET_GIFT_CARDS_REQUEST,
  payload: {
    locale
  }
})

export const getGiftCardsSuccess = (payload) => ({
  type: GET_GIFT_CARDS_SUCCESS,
  payload
})

export const getGiftCardsFailed = () => ({
  type: GET_GIFT_CARDS_FAILED,
  payload: {}
})
