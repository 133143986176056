import qs from "qs";

const subscriptions = () => {
  const query = qs.stringify({
    populate: [
      'options.headerImage',
      'options.items',
      'includes'
    ]
  })
  return '/subscription?' + query
}

export default subscriptions