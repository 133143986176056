import process from 'process';
import { useEffect } from 'react';
import { setCartId } from '../../lib/utils/circuly';
import { useRouter } from 'next/router';
import { DEFAULT_LOCALE } from '../../lib/constants';

export function CirculySetup() {
  const router = useRouter();

  const addCirculyScript = (locale: string) => {
    const circulyConfig = {
      api_key: process.env.NEXT_PUBLIC_CIRCULY_API_KEY, // eslint-disable-line @typescript-eslint/naming-convention
      checkout: process.env.NEXT_PUBLIC_CHECKOUT_URL,
      redirect_on_post: 'true', // eslint-disable-line @typescript-eslint/naming-convention
      options: {
        default_locale: locale,
      },
    };

    const element = document.querySelector('div[data-embed]');

    if (element) {
      const script = document.createElement('script');
      script.type = 'application/json';
      script.innerHTML = JSON.stringify(circulyConfig);

      const prevScript = element.querySelector('script');

      if (prevScript) {
        element.replaceChild(script, prevScript);
      } else {
        element.appendChild(script);
      }
    }
  };

  useEffect(() => {
    addCirculyScript(router.locale || DEFAULT_LOCALE);
  }, [router.locale]);

  useEffect(() => {
    const callback = (event: any) => {
      const cart = event.detail;
      setCartId(cart.cart.cart_id); // eslint-disable-line @typescript-eslint/no-unsafe-argument
    };

    // Listener on circuly sdk event on cart changes to get the cart
    window.addEventListener('changed-cart', callback);

    return () => {
      window.removeEventListener('changed-cart', callback);
    };
  }, []);

  return (
    <>
      <div data-embed="circuly-basket" />
      <div id="circuly-cart" />
    </>
  );
}
