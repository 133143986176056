import React, { useEffect } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';

declare global {
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/consistent-type-definitions
  interface Window {
    gtag?: (
      a: string,
      b: string,
      c: { page_path?: string; send_to?: string }
    ) => void;
    Trustpilot: {
      loadFromElement: (a: any, b: boolean) => void;
    };
  }
}

export function Analytics(props: {
  readonly gaMeasurementId: string;
  readonly adwordsId?: string;
  readonly awinAdvertiserId: string;
}) {
  const router = useRouter();

  useEffect(() => {
    if (!props.gaMeasurementId || !window.gtag) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const pageViewOptions = { page_path: router.asPath };
    window.gtag?.('config', props.gaMeasurementId, pageViewOptions);
    if (props.adwordsId) {
      window.gtag?.('config', props.adwordsId, pageViewOptions);
    }
  }, [props.gaMeasurementId, props.adwordsId, router]);

  return (
    <>
      <Script
        src={`https://www.googletagmanager.com/gtag/js?id=${props.gaMeasurementId}`}
        strategy="afterInteractive"
      />
      <Script id="google-analytics" strategy="afterInteractive">
        {`
      window.dataLayer = window.dataLayer || [];
      function gtag(){window.dataLayer.push(arguments);}
      gtag('js', new Date());

      gtag('config', '${props.gaMeasurementId}');
      ${props.adwordsId ? `gtag('config', '${props.adwordsId}');` : ''}
    `}
      </Script>

      <Script id="meta-analytics" strategy="afterInteractive">
        {`!function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '774699583164477');
      fbq('track', 'PageView');`}
      </Script>

      <Head>
        <link
          rel="preconnect dns-prefetch"
          href="https://api.config-security.com/"
          crossOrigin="anonymous"
        />
      </Head>

      {/* CONVERGE integration */}
      <Script async src="https://static.runconverge.com/pixels/1QpA6F.js" />
      <Script id="converge-analytics">
        {`window.cvg||(cvg=function(){cvg.process?cvg.process.apply(cvg,arguments):cvg.queue.push(arguments)},cvg.queue=[]);
        cvg({method:"track",eventName:"$page_load"});`}
      </Script>

      {/* Trustpilot integration */}
      <Script
        async
        type="text/javascript"
        src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
      />
      {/* AWIN integration */}
      <Script src={`https://www.dwin1.com/${props.awinAdvertiserId}.js`} defer></Script>
    </>
  );
}
