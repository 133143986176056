import qs from "qs";

const config = () => {
  const query = qs.stringify({
    populate: [
      'metaImage',
    ]
  })
  return '/global-config?' + query
}

export default config