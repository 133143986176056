import qs from "qs";

const ourExpertsPage = () => {
  const query = qs.stringify({
    populate: [
      'benefits.icon',
      'experts.avatar',
      'image'
    ]
  })
  return '/our-experts-page?' + query
}

export default ourExpertsPage