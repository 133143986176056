import { useAppContext } from "../../context/shared";

const CartDropdownEmptyState = () => {

  const { localization } = useAppContext()

  return (
    <>
      <div className='px-5 py-3 w-100 bg-gray-1 text-center rounded'>
        <img alt='empty state' src='/images/cart_dropdown_empty_state.png' className='img-fluid' />
        <div className='caption fw-bold'>{localization.cart_empty_text}</div>
      </div>
    </>
  )
}

export default CartDropdownEmptyState