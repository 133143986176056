import {
  type FooterDTO,
  type NavbarDTO,
  type Locale,
  type HeroDTO,
  type HowItWorksDTO,
  type GiftCardSectionDTO,
  type PageSectionDTO,
  type QuoteDTO,
  type NewsletterFormDTO,
  type FooterCMSData,
  type NavbarCMSData,
  type HeroCMSData,
  type FooterColumnCMSData,
  type FooterColumnItemCMSData,
  type GiftCardSectionCMSData,
  type PageSectionCMSData,
  type QuoteCMSData,
  type NewsletterFormCMSData,
  type FooterSocialLinkCMSData,
  type AuthorCMSData,
  type AuthorDTO,
  type CategoryCMSData,
  type CategoryDTO,
  type ArticleCMSData,
  type ArticleDTO,
  type HowItWorksCMSData,
  type HowItWorksStepCMSData,
  type StarterBoxSectionCMSData,
  type StarterBoxSectionDTO,
  type ActivityStepCMSData,
  type ActivityStepDTO,
  type LocalizationCMSData,
  type LocalizationDTO,
  type ActivityGuidePageDTO,
  type ActivityGuidePageCMSData,
  type CmsHowToUseDto,
  type HowItWorksPageCMSData,
  type HowItWorksPageDTO,
  type HowItWorksPageStepCMSData,
  type HowItWorksPageStepDTO,
  type AboutPageDTO,
  type CookieConsentCMSData,
  type CookieConsentDTO,
  type OurExpertsPageCMSData,
  type OurExpertsPageDTO,
  type ExpertCMSData,
  type ExpertDTO,
  type AboutPageCMSData,
  type SubpageCMSData,
  type SubpageDTO,
  type ConfigCMSData,
  type ConfigDTO,
  TextCMSData,
  TextDTO,
} from '../../types';
import { IMAGE_SIZES } from '../../constants';
import { imageFormatter } from '../image';
import { mapOrderBenefitData } from './map-order-benefit-data';

export const mapFooterData = (
  footer: FooterCMSData,
  locale: Locale
): FooterDTO => {
  return {
    emailAddress: footer?.emailAddress,
    subfooter: {
      columns: (footer?.subfooter?.columns || []).map(
        (column: FooterColumnCMSData) => {
          return {
            title: column?.['title_' + locale],
            items: column?.items.map((item: FooterColumnItemCMSData) => {
              return {
                title: item?.['name_' + locale],
                link: item.link,
              };
            }),
          };
        }
      ),
    },
    columns: (footer?.columns || []).map((column: FooterColumnCMSData) => {
      return {
        title: column?.['title_' + locale],
        items: column?.items.map((item: FooterColumnItemCMSData) => {
          return {
            title: item?.['name_' + locale],
            link: item?.link,
          };
        }),
      };
    }),
    socialLinks: (footer?.socialLinks || []).map(
      (socialLink: FooterSocialLinkCMSData) => {
        return {
          link: socialLink?.link,
          logo: imageFormatter(socialLink?.logo, IMAGE_SIZES.large),
        };
      }
    ),
    socialLinksTitle: footer?.['socialLinksTitle_' + locale] || '',
    logo: imageFormatter(footer?.logo, IMAGE_SIZES.large),
    sponsorLogo: imageFormatter(footer?.sponsorLogo, IMAGE_SIZES.small),
    sponsorLogoLink: footer?.sponsorLogoLink || '',
    sponsorLogoText: footer?.['sponsorLogoText_' + locale] || '',
    associatedCompaniesTitle:
      footer?.['associatedCompaniesTitle_' + locale] || '',
  };
};

export const mapNavbarData = (
  navbar: NavbarCMSData,
  locale: Locale
): NavbarDTO => {
  return {
    announcement: navbar?.['announcement_' + locale],
    items: (navbar?.navbarItems || []).map((item: any) => {
      return {
        title: item['name_' + locale],
        link: item.link,
      };
    }),
    logo: imageFormatter(navbar?.logo, IMAGE_SIZES.large),
  };
};

export const mapTextData = (input: TextCMSData, locale: Locale): TextDTO => ({
  text: input['text_' + locale] ?? '',
});

export const mapHeroData = (hero: HeroCMSData, locale: Locale): HeroDTO => {
  return {
    title: hero?.['heroTitle_' + locale] || '',
    subtitle: hero?.['heroSubtitle_' + locale] || '',
    ctaText: hero?.['heroCtaText_' + locale] || '',
    ratingsText: hero?.['heroRatingsText_' + locale] ?? '',
    checks: hero?.heroChecks?.map((e) => mapTextData(e, locale)) ?? [],
    ctaLink: hero?.heroCtaLink || '',
    backgroundImage: imageFormatter(
      hero?.heroBackgroundImage,
      IMAGE_SIZES.xlarge
    ),
    mobileBackgroundImage: imageFormatter(
      hero?.heroMobileBackgroundImage,
      IMAGE_SIZES.xlarge
    ),
  };
};

export const mapHowItWorksData = (
  howItWorks: HowItWorksCMSData,
  locale: Locale
): HowItWorksDTO => {
  return {
    title: howItWorks?.['title_' + locale] || '',
    howItWorksStep: (howItWorks?.howItWorksStep || []).map(
      (step: HowItWorksStepCMSData) => {
        return {
          title: step['title_' + locale] || '',
          description: step['description_' + locale] || '',
          icon: imageFormatter(step.icon, IMAGE_SIZES.medium) || '',
        };
      }
    ),
  };
};

export const mapGiftCardSectionData = (
  giftCardSection: GiftCardSectionCMSData,
  locale: Locale
): GiftCardSectionDTO => {
  return {
    title: giftCardSection?.['title_' + locale] || '',
    description: giftCardSection?.['description_' + locale] || '',
    buttonText: giftCardSection?.['buttonText_' + locale] || '',
    image: imageFormatter(giftCardSection?.image, IMAGE_SIZES.large),
  };
};

export const mapPageSectionData = (
  pageSection: PageSectionCMSData,
  locale: Locale
): PageSectionDTO => {
  return {
    title: pageSection?.['title_' + locale] || '',
    backgroundColor: pageSection?.backgroundColor || '',
    titleImage:
      imageFormatter(pageSection?.titleImage, IMAGE_SIZES.large) || '',
  };
};

export const mapQuoteData = (quote: QuoteCMSData, locale: Locale): QuoteDTO => {
  return {
    source: quote?.source,
    quote: quote?.['quote_' + locale],
    logo: imageFormatter(quote.logo, IMAGE_SIZES.large),
  };
};

export const mapNewsletterFormData = (
  newsletter: NewsletterFormCMSData,
  locale: Locale
): NewsletterFormDTO => {
  return {
    title: newsletter?.['title_' + locale],
    subtitle: newsletter?.['subtitle_' + locale],
    buttonText: newsletter?.['buttonText_' + locale],
    placeholder: newsletter?.['placeholder_' + locale],
    backgroundColor: newsletter?.backgroundColor,
    backgroundImage: imageFormatter(
      newsletter?.backgroundImage,
      IMAGE_SIZES.large
    ),
    invalidEmailErrorMessage:
      newsletter?.['invalidEmailErrorMessage_' + locale] || '',
    errorMessage: newsletter?.['errorMessage_' + locale] || '',
    successTitle: newsletter?.['successTitle_' + locale] || '',
    successDescription: newsletter?.['successDescription_' + locale] || '',
  };
};

export const mapAuthorData = (
  author: AuthorCMSData,
  locale: Locale
): AuthorDTO => {
  return {
    id: author.id,
    name: author.attributes?.name,
    bio: author.attributes?.['bio_' + locale],
    image: imageFormatter(author?.attributes?.image, IMAGE_SIZES.large),
  };
};

export const mapCategoryData = (
  category: CategoryCMSData,
  locale: Locale
): CategoryDTO => {
  return {
    id: category.id,
    name: category.attributes?.['name_' + locale],
  };
};

export const mapArticleCategoryData = (
  category: CategoryCMSData,
  locale: Locale
): CategoryDTO => {
  return {
    id: category.id,
    name: category?.attributes?.['name_' + locale],
  };
};

export const mapArticleData = (
  article: ArticleCMSData,
  locale: Locale
): ArticleDTO => {
  return {
    slug: article?.slug || '',
    content: article?.['content_' + locale] || '',
    title: article?.['title_' + locale] || '',
    intro: article?.['intro_' + locale] || '',
    mainImage: imageFormatter(article?.mainImage, IMAGE_SIZES.large)
      ? imageFormatter(article?.mainImage, IMAGE_SIZES.large)
      : '/images/blog-default-image.png',
    categories: (article?.blogPostCategories?.data || []).map(
      (category: any) => {
        return mapArticleCategoryData(category, locale);
      }
    ),
    publishedAt: article?.publishedAt || '',
    author: article?.author?.data
      ? mapAuthorData(article?.author?.data, locale)
      : null,
  };
};

export const mapHowToUseData = (
  howToUse: CmsHowToUseDto,
  locale: Locale
): string => {
  return howToUse?.['content_' + locale] || '';
};

export const mapStarterBoxSectionData = (
  data: StarterBoxSectionCMSData,
  locale: Locale
): StarterBoxSectionDTO => {
  return {
    title: data?.['title_' + locale] || '',
    subtitle: data?.['subtitle_' + locale] || '',
    buttonLabel: data?.['button_label_' + locale] || '',
    backgroundPattern: imageFormatter(
      data?.backgroundPattern,
      IMAGE_SIZES.large
    ),
    ageFilters: (data?.ageFilters || []).map((filter) => ({
      name: filter['name_' + locale] || '',
      minMonths: filter.minMonths,
      maxMonths: filter.maxMonths,
    })),
  };
};

export const mapActivityGuidePageData = (
  data: ActivityGuidePageCMSData,
  locale: Locale
): ActivityGuidePageDTO => {
  return {
    title: data?.['title_' + locale] || '',
    ageGroupSelectorTitle: data?.['ageGroupSelectorTitle_' + locale] || '',
    description: data?.['description_' + locale] || '',
    boxItemToggle: data?.['boxItemToggle_' + locale] || '',
    benefitLabelColors: (data?.benefitLabelColors || '').split(', '),
  };
};

export const mapActivityStepData = (
  data: ActivityStepCMSData,
  locale: Locale
): ActivityStepDTO => {
  return {
    title: data?.['title_' + locale] || '',
    description: data?.['description_' + locale] || '',
  };
};

export const mapLocalizationData = (
  data: LocalizationCMSData,
  locale: Locale
): LocalizationDTO => {
  return {
    key: data.attributes.key,
    value: data.attributes?.['value_' + locale] || '',
  };
};

export const mapHowItWorksPageData = (
  data: HowItWorksPageCMSData,
  locale: Locale
): HowItWorksPageDTO => {
  return {
    title: data?.['title_' + locale] || '',
    description: data?.['description_' + locale] || '',
    step: (data?.step || []).map((_step: HowItWorksPageStepCMSData) =>
      mapHowItWorksPageStepData(_step, locale)
    ),
    backgroundPattern: imageFormatter(
      data?.backgroundPattern,
      IMAGE_SIZES.large
    ),
  };
};

export const mapHowItWorksPageStepData = (
  data: HowItWorksPageStepCMSData,
  locale: Locale
): HowItWorksPageStepDTO => {
  return {
    title: data?.['title_' + locale] || '',
    description: data?.['description_' + locale] || '',
    imagePosition: data?.imagePosition || '',
    image: imageFormatter(data?.image, IMAGE_SIZES.large),
    variant: data?.variant || '',
    showArrowBelow: data.showArrowBelow || null,
  };
};

export const mapAboutPageData = (
  data: AboutPageCMSData,
  locale: Locale
): AboutPageDTO => {
  return {
    title: data?.['title_' + locale] || '',
    quote: data?.['quote_' + locale] || '',
    quoteSource: data?.['quoteSource_' + locale] || '',
    content: data?.['content_' + locale] || '',
    image: imageFormatter(data?.image, IMAGE_SIZES.large),
  };
};

export const mapCookieConsentData = (
  data: CookieConsentCMSData,
  locale: Locale
): CookieConsentDTO => {
  return {
    cookieAnnouncement: data?.['cookieAnnouncement_' + locale] || '',
    policyLinkText: data?.['policyLinkText_' + locale] || '',
    link: data?.link || '',
    declineButtonText: data?.['declineButtonText_' + locale] || '',
    allowButtonText: data?.['allowButtonText_' + locale] || '',
  };
};

export const mapExpertData = (
  data: ExpertCMSData,
  locale: Locale
): ExpertDTO => {
  return {
    name: data?.name || '',
    bio: data?.['bio_' + locale] || '',
    profession: data?.['profession_' + locale],
    avatar: imageFormatter(data?.avatar, IMAGE_SIZES.medium),
  };
};

export const mapOurExpertsPageData = (
  data: OurExpertsPageCMSData,
  locale: Locale
): OurExpertsPageDTO => {
  return {
    title: data?.['title_' + locale] || '',
    description: data?.['description_' + locale] || '',
    benefits: (data?.benefits || []).map((orderBenefit) =>
      mapOrderBenefitData(orderBenefit, locale)
    ),
    image: imageFormatter(data?.image, IMAGE_SIZES.large),
    expertsTitle: data?.['expertsTitle_' + locale] || '',
    expertsDescription: data?.['expertsDescription_' + locale] || '',
    experts: (data?.experts || []).map((expert) =>
      mapExpertData(expert, locale)
    ),
    referencesTitle: data?.['referencesTitle_' + locale] || '',
    referencesDescription: data?.['referencesDescription_' + locale] || '',
    references: data?.['references_' + locale] || '',
  };
};

export const mapSubpageData = (
  data: SubpageCMSData,
  locale: Locale
): SubpageDTO => {
  return {
    shareTitle: data?.['shareTitle_' + locale] || '',
    shareDescription: data?.['shareDescription_' + locale] || '',
    content: data?.['content_' + locale] || '',
  };
};

export const mapConfigData = (
  data: ConfigCMSData,
  locale: Locale
): ConfigDTO => {
  return {
    metaTitle: data?.['metaTitle_' + locale] || '',
    metaDescription: data?.['metaDescription_' + locale] || '',
    metaImage: imageFormatter(data?.metaImage, IMAGE_SIZES.medium),
  };
};