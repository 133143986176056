import qs from 'qs';

export function boxes() {
  const query = qs.stringify({
    populate: [
      'age_group',
      'headerImage.format',
      'overviewImage.format',
      'boxContentList',
      'box_items.benefitTags',
      'box_items.howToUse',
      'box_items.fromAge',
      'box_items.ourBoxesImage',
    ],
  });
  return '/boxes?' + query;
}
