import React, { useEffect, useRef, useState } from 'react';
import Link from 'next/link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  faUser,
  faBasketShopping,
  faCircleNotch,
  faBars,
} from '@fortawesome/free-solid-svg-icons';
import { useRouter } from 'next/router';
import { Button, Container, Dropdown, Nav, Navbar } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash.debounce';
import throttle from 'lodash.throttle';
import { createSelector } from '@reduxjs/toolkit';
import Flag from 'react-world-flags';
import { useAppContext } from '../../context/shared';
import CartDropdown from '../Cart/CartDropdown';
import { clickedCTA } from '../../store/actions/events';
import { ctaClickSources } from '../../lib/constants';
import { type NavbarProps } from '../../lib/types';
import styles from '../../styles/Navigation.module.css';

function Navigation({ announcement, logo, items, loginUrl }: NavbarProps) {
  const { localization } = useAppContext();
  const router = useRouter();
  const dispatch = useDispatch();

  const [expanded, setExpanded] = useState(false);
  const [showCartDropdown, setShowCartDropdown] = useState(false);

  const selectCart = (state) => state.cart;
  const selectBoxes = (state) => state.boxes;
  const selectCartAndBoxes = createSelector(
    selectCart,
    selectBoxes,
    (cart, boxes) => ({ cart, boxes })
  );
  const { cart, boxes } = useSelector(selectCartAndBoxes);

  const headerRef = useRef<HTMLDivElement>(null);

  const [topPosition, _setTopPosition] = useState(0);
  const topPositionRef = useRef(topPosition);
  const setTopPosition = (data) => {
    data = Math.max(0, Math.min(headerRef?.current?.offsetHeight || 0, data));
    topPositionRef.current = data;
    _setTopPosition(data);
  };

  const [scrollPosition, _setScrollPosition] = useState(1);
  const scrollPositionRef = useRef(scrollPosition);
  const setScrollPosition = (data) => {
    scrollPositionRef.current = data;
    _setScrollPosition(data);
  };

  const onScroll = (e) => {
    if (window.innerWidth < 768) {
      const diff = scrollPositionRef.current - window.pageYOffset;
      setTopPosition(
        window.pageYOffset === 0 ? 0 : topPositionRef.current - diff
      );
      setScrollPosition(Math.max(0, window.pageYOffset));
    } else if (topPositionRef.current !== 0) {
      setTopPosition(0);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', throttle(onScroll, 10));
    return () => {
      window.removeEventListener('scroll', throttle(onScroll, 10));
    };
  }, []);

  useEffect(() => {
    setExpanded(false);
    setShowCartDropdown(false);
  }, [router.pathname]);

  const handleCartDropdownMouseLeave = debounce(() => {
    setShowCartDropdown(false);
  }, 300);

  const handleCartDropdownMouseEnter = () => {
    handleCartDropdownMouseLeave.cancel();
    setShowCartDropdown(true);
  };

  const clickAnnouncement = (e) => {
    if (e.target.tagName === 'A') {
      e.preventDefault();
      console.log(e);
      const { href } = e.target;
      const targetId = href.replace(/.*#/, '');
      const element = document.getElementById(targetId);
      element?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div ref={headerRef} className="fixed-top" style={{ top: -topPosition }}>
      <CartDropdown
        visible={showCartDropdown}
        handleMouseEnter={handleCartDropdownMouseEnter}
        handleMouseLeave={handleCartDropdownMouseLeave}
        cart={cart}
        boxes={boxes}
      />
      {announcement && (
        <div
          dangerouslySetInnerHTML={{ __html: announcement }}
          className={`px-2 ${styles.announcementBar}`}
          onClick={clickAnnouncement}
        />
      )}
      <Navbar
        bg="white"
        expand="xl"
        className={`${styles.shadow} px-md-5`}
        expanded={expanded}
      >
        <Container fluid>
          <Navbar.Brand className="mr-auto">
            <Link href="/">
              <img
                width="88px"
                alt="logo"
                className="clickable"
                srcSet={logo ? logo : '/images/logo.png 2x'}
              />
            </Link>
          </Navbar.Brand>

          <Nav className="ml-auto justify-content-end flex-row gap-2">
            <Dropdown className="d-none d-xl-block">
              <Dropdown.Toggle
                variant="outline-light"
                className="h-100 d-flex align-items-center gap-1 border-0"
              >
                <Nav.Link>
                  <span className={`me-1 ${styles.language}`}>
                    {router.locale?.toUpperCase()}
                  </span>
                  <FontAwesomeIcon icon={faChevronDown} />
                </Nav.Link>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {router?.locales?.map((item) => (
                  <Dropdown.Item key={'locale-menu-' + item}>
                    <Link
                      className={styles.link}
                      href={router.asPath}
                      locale={item}
                      passHref
                    >
                      {item.toUpperCase()} &nbsp;
                      <Flag
                        code={item === 'en' ? 'GB' : item}
                        height="12"
                        className="me-1"
                      />
                    </Link>
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
            {loginUrl && (
              <Nav className="px-2 me-2 align-items-center flex-row">
                <Nav.Link href={loginUrl} target="_blank">
                  <FontAwesomeIcon icon={faUser} />
                </Nav.Link>
              </Nav>
            )}
            <Link href="/cart">
              <Button
                className="d-flex gap-2 align-items-center"
                variant="outline-primary"
                disabled={cart.loading || !cart.ready}
                onMouseEnter={() => {
                  handleCartDropdownMouseEnter();
                }}
                onMouseLeave={() => handleCartDropdownMouseLeave()}
                onClick={() =>
                  dispatch(
                    clickedCTA({
                      name: ctaClickSources.navbar.name,
                      url: router.asPath,
                    })
                  )
                }
              >
                <div className="d-none d-xxl-block">
                  {localization.cart_btn}
                </div>
                {(cart.loading || !cart.ready) && (
                  <FontAwesomeIcon spin icon={faCircleNotch} />
                )}
                {cart.ready &&
                  !cart.loading &&
                  (cart?.data?.items?.length ? (
                    <div className={styles.cartCount}>
                      <div className={styles.cartCountInner}>
                        {cart.data.items.reduce(
                          (result, item) => item.quantity + result,
                          0
                        )}
                      </div>
                    </div>
                  ) : (
                    <FontAwesomeIcon icon={faBasketShopping} />
                  ))}
              </Button>
            </Link>
            <Navbar.Toggle
              className="btn border-0 mx-2"
              type="button"
              data-toggle="collapse"
              data-target="#basic-navbar-nav"
              aria-controls="basic-navbar-nav"
              aria-expanded="false"
              onClick={() => {
                setExpanded(!expanded);
              }}
            >
              <FontAwesomeIcon icon={faBars} />
            </Navbar.Toggle>
          </Nav>

          <Navbar.Collapse
            id="basic-navbar-nav"
            className={`${styles.collapse} pe-4`}
            data-toggle="collapse"
            data-target=".navbar-collapse"
          >
            <Nav className="mx-auto w-100 justify-content-center gap-5">
              {(items || []).map((item, index) => (
                <Nav
                  key={item.title + '_' + index}
                  className={`text-end ${styles.navbarItems}`}
                  onClick={() => {
                    setExpanded(false);
                  }}
                >
                  <Link href={item.link}>
                    <div
                      className={[
                        styles.navbarItem,
                        'clickable',
                        router.route === item.link
                          ? 'text-decoration-underline text-grayscale-black'
                          : '',
                      ].join(' ')}
                    >
                      {item.title}
                    </div>
                  </Link>
                </Nav>
              ))}

              <div className="d-xl-none mb-3">
                {router?.locales && <div className="mb-5" />}

                {router?.locales?.map((item) => (
                  <Nav
                    key={'locale-' + item}
                    className={`text-end mb-3 ${styles.navbarItems}`}
                    onClick={() => {
                      setExpanded(false);
                    }}
                  >
                    <Link passHref href={router.asPath} locale={item}>
                      <div className="text-dark clickable">
                        {item.toUpperCase()}
                        <Flag
                          code={item === 'en' ? 'GB' : item}
                          height="12"
                          className="me-1"
                        />
                      </div>
                    </Link>
                  </Nav>
                ))}
              </div>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}

export default Navigation;
