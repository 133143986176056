import process from 'process';
import axios, { type AxiosResponse } from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.CMS_URL + `/api`, // eslint-disable-line @typescript-eslint/naming-convention
});

const api = {
  async get<T = any>(path: string): Promise<AxiosResponse<T> | undefined> {
    return axiosInstance.get<T>(path).catch((error) => {
      console.error(error?.response?.data);
      return undefined;
    });
  },
};

export default api;
