import { Locale } from "../../lib/types"

export const GET_BOXES_REQUEST = "BOXES/getRequest"
export const GET_BOXES_SUCCESS = "BOXES/getSuccess"
export const GET_BOXES_FAILED = "BOXES/getFailed"

export const getBoxesRequest = (locale: Locale) => ({
  type: GET_BOXES_REQUEST,
  payload: {
    locale
  }
})

export const getBoxesSuccess = (payload) => ({
  type: GET_BOXES_SUCCESS,
  payload
})

export const getBoxesFailed = () => ({
  type: GET_BOXES_FAILED,
  payload: {}
})
