import qs from "qs";

const footer = () => {
  const query = qs.stringify({
    populate: [
      'logo',
      'sponsorLogo',
      'columns.items',
      'socialLinks.logo'
    ]
  })
  return '/footer?' + query
}

export default footer