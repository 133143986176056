import qs from "qs";

const popularBlogPosts = () => {
  const query = qs.stringify({
    populate: [
      'mainImage',
      'author.image',
      'blogPostCategories'
    ],
    sort: [ "publishedAt:desc" ],
    pagination: {
      start: 1,
      limit: 3
    }
  })
  return '/blog-posts?' + query
}

export default popularBlogPosts