import qs from "qs";

const newsletter = () => {
  const query = qs.stringify({
    populate: [
      'backgroundImage'
    ]
  })
  return '/newsletter?' + query
}

export default newsletter