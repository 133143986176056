import { COOKIE_CONSENT_NAME } from "../constants"
import cookies from "js-cookie"

export const get = (): boolean => {
  const consent = cookies.get(COOKIE_CONSENT_NAME)
  try {
    const parsed = JSON.parse(consent || "")
    return parsed || false
  } catch (error) {
    return false
  }
}

export const set = (consent: boolean) => {
  cookies.set(COOKIE_CONSENT_NAME, JSON.stringify({ accepted: consent }), {
    expires: 120,
    sameSite: 'strict',
    secure: true
  })
}
