import { combineReducers } from 'redux';
import { routerReducer } from 'connected-next-router';
import cart from './cart';
import boxes from './boxes';
import giftCards from './giftCards';
import cookieConsent from './cookie-consent';

const createRootReducer = () =>
  combineReducers({
    cart,
    boxes,
    giftCards,
    cookieConsent,
    router: routerReducer,
  });

export default createRootReducer;
