import { type Action } from '../../lib/types';
import { type BoxData } from '../../lib/types/data/box-data';
import {
  GET_BOXES_FAILED,
  GET_BOXES_REQUEST,
  GET_BOXES_SUCCESS,
} from '../actions/boxes';

const initial: {
  loading: boolean;
  ready: boolean;
  error: boolean;
  data: BoxData[];
} = {
  loading: false,
  ready: false,
  error: false,
  data: [],
};

const boxes = (state = initial, action: Action<any>) => {
  switch (action.type) {
    case GET_BOXES_REQUEST: {
      return { ...state, loading: true, error: false };
    }

    case GET_BOXES_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        ready: true,
        data: action.payload,
      };
    }

    case GET_BOXES_FAILED: {
      return { ...state, loading: false, error: true };
    }

    default: {
      return state;
    }
  }
};

export default boxes;
