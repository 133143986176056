import { IMAGE_SIZES } from '../../constants';
import { type PopUpData, type Locale } from '../../types';
import { type CmsPopUpDto } from '../../types/cms/cms-popup-dto';
import { imageFormatter } from '../image';

export const mapPopUpData = (
  popUp: CmsPopUpDto,
  locale: Locale
): PopUpData | undefined => {
  if (!popUp) return undefined;
  const { attributes } = popUp;

  const titleKey = `title_${locale}` as const;
  const subtitleKey = `subtitle_${locale}` as const;
  const extraInformationKey = `extraInformation_${locale}` as const;
  const buttonTextKey = `buttonText_${locale}` as const;
  const placeholderKey = `placeholder_${locale}` as const;
  const invalidEmailErrorMessageKey =
    `invalidEmailErrorMessage_${locale}` as const;
  const errorMessageKey = `errorMessage_${locale}` as const;
  const successTitleKey = `successTitle_${locale}` as const;
  const successDescriptionKey = `successDescription_${locale}` as const;
  return {
    title: attributes[titleKey],
    subtitle: attributes[subtitleKey],
    extraInformation: attributes[extraInformationKey],
    buttonText: attributes[buttonTextKey],
    placeholder: attributes[placeholderKey],
    backgroundColor: attributes.backgroundColor,
    backgroundImage: imageFormatter(
      attributes.backgroundImage,
      IMAGE_SIZES.large
    ),
    decoratorImage: imageFormatter(
      attributes.decorationImage,
      IMAGE_SIZES.small
    ),
    invalidEmailErrorMessage: attributes[invalidEmailErrorMessageKey],
    errorMessage: attributes[errorMessageKey],
    successTitle: attributes[successTitleKey],
    successDescription: attributes[successDescriptionKey],
  };
};
