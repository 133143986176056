import { type Action } from '../../lib/types';
import {
  getConsentRequestToken,
  getConsentSuccessToken,
  setConsentRequestToken,
  setConsentSuccessToken,
} from '../actions/cookie-consent';

const initial: {
  loading: boolean;
  ready: boolean;
  error: boolean;
  data: { accepted: boolean } | undefined;
} = {
  loading: false,
  ready: false,
  error: false,
  data: {
    accepted: true,
  },
};

const cookieConsent = (
  state = initial,
  action: Action<{ accepted: boolean }>
) => {
  switch (action.type) {
    case getConsentRequestToken: {
      return { ...state, loading: true, error: false };
    }

    case getConsentSuccessToken: {
      return {
        ...state,
        loading: false,
        error: false,
        ready: true,
        data: action.payload,
      };
    }

    case setConsentRequestToken: {
      return { ...state, loading: true, error: false };
    }

    case setConsentSuccessToken: {
      return {
        ...state,
        loading: false,
        error: false,
        ready: true,
        data: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default cookieConsent;
