import qs from "qs";

const blogPost = (
  { slug, sort, offset, limit, categories, authors, ageGroups }:
    { slug?: string, sort?: string, offset?: number, limit?: number, categories?: string[], authors?: string[], ageGroups?: string[] }
) => {
  const query = qs.stringify({
    populate: [
      'mainImage',
      'author.image',
      'blogPostCategories'
    ],
    filters: {
      ...(slug && {
        slug: { $eq: slug }
      }),
      ...(categories && {
        blogPostCategories: { id: { $in: categories } }
      }),
      ...(authors && {
        author: { id: { $in: authors } }
      }),
      ...(ageGroups && {
        ageGroup: { id: { $in: ageGroups } }
      })
    },
    ...(sort && {
      sort: ['publishedAt:' + sort]
    }),
    pagination: {
      ...(offset && {
        start: offset
      }),
      ...(limit && {
        limit
      }),
      withCount: true
    }
  })
  return '/blog-posts?' + query
}

export default blogPost