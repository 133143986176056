import { type CmsImageField } from '../types';
import { type IMAGE_SIZES } from '../constants';

export const imageFormatter = (
  parentObject: CmsImageField,
  requiredSize: keyof typeof IMAGE_SIZES
): string => {
  if (parentObject?.data?.attributes?.formats?.[requiredSize])
    return parentObject.data.attributes.formats?.[requiredSize]?.url ?? '';
  if (!parentObject?.data?.attributes?.url) return '';
  return parentObject.data.attributes.url;
};
