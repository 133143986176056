import { EVENTS } from "../../lib/constants"
import { SubscriptionVariant } from "../../lib/types"

export const PAGE_VISIT = "EVENTS/pageVisit"
export const ADDED_TO_CART = "EVENTS/addedToCart"
export const REMOVED_FROM_CART = "EVENTS/removedFromCart"
export const CHECKED_OUT = "EVENTS/checkedOut"
export const CLICKED_CTA ='EVENTS/clickedCTA'
export const ADD_TO_CART_ERROR = 'EVENTS/addToCartError'
export const SELECTED_BOX = 'EVENTS/selectedBox'

export const pageVisit = (payload: { url: string, statusCode: number }) => ({
  type: PAGE_VISIT,
  payload: {
    eventCode: EVENTS.visited.code,
    url: payload.url,
    statusCode: payload.statusCode,
    eventName: EVENTS.visited.name
  }
})

export const addedToCart = (payload: { boxTitle: string, subscription?: SubscriptionVariant }) => ({
  type: ADDED_TO_CART,
  payload: {
    eventCode: EVENTS.addedToCart.code,
    boxTitle: payload.boxTitle,
    subscription: payload.subscription || false,
    eventName: EVENTS.addedToCart.name
  }
})

export const addToCartError = (payload) => ({
  type: ADD_TO_CART_ERROR,
  payload: {
    eventCode: EVENTS.addToCartError.code,
    eventName: EVENTS.addToCartError.name,
    id: payload.id,
    subscription: payload.subscription || false,
    ...(payload.errorMessage && { errorMessage: payload.errorMessage }),
    ...(payload.statusCode && { statusCode: payload.statusCode }),
    ...(payload.statusText && { statusText: payload.statusText }),
    ...(payload.error && { error: payload.error })
  }
})

export const removedFromCart = (payload: { boxTitle: string, subscription: SubscriptionVariant }) => ({
  type: REMOVED_FROM_CART,
  payload: {
    eventCode: EVENTS.removedFromCart.code,
    boxTitle: payload.boxTitle,
    subscription: payload.subscription,
    eventName: EVENTS.removedFromCart.name
  }
})

export const checkedOut = (payload: {
  cart_id: string,
  cart_total_quantity: number,
  voucher_discount: number,
  voucher_email: any,
  sum_subscriptions: number,
  sum_total: number,
  location: any
}) => ({
  type: CHECKED_OUT,
  payload: {
    eventCode: EVENTS.checkedOut.code,
    eventName: EVENTS.checkedOut.name,
    cart_id: payload.cart_id,
    cart_total_quantity: payload.cart_total_quantity,
    voucher_discount: payload.voucher_discount,
    voucher_email: payload.voucher_email,
    sum_subscriptions: payload.sum_subscriptions,
    sum_total: payload.sum_total,
    location: payload.location
  }
})

// eslint-disable-next-line @typescript-eslint/naming-convention
export const clickedCTA = (payload: {
  name: string;
  url: string;
  subscription?: string;
  shopifyId?: string;
  variantId?: string;
  email?: string;
  blogPostTitle?: string;
  boxTitle?: string;
}) => ({
  type: CLICKED_CTA,
  payload: {
    eventCode: EVENTS.clickedCTA.code,
    eventName: EVENTS.clickedCTA.name,
    ...payload,
  },
});

export const selectedBox = (payload: { shopifyId: string, shopifyTitle: string, boxTitle: string }) => ({
  type: SELECTED_BOX,
  payload: {
    eventCode: EVENTS.selectedBox.code,
    eventName: EVENTS.selectedBox.name,
    shopifyId: payload.shopifyId,
    shopifyTitle: payload.shopifyTitle,
    boxTitle: payload.boxTitle
  }
})
