import { all, takeLatest } from 'redux-saga/effects';
import {
  ADD_TO_CART_REQUEST,
  GET_CART_REQUEST,
  GO_TO_CHECKOUT,
  RESET_CART,
} from '../store/actions/cart';
import { GET_BOXES_REQUEST } from '../store/actions/boxes';
import { GET_GIFT_CARDS_REQUEST } from '../store/actions/giftCards';
import {
  getConsentRequestToken,
  setConsentRequestToken,
} from '../store/actions/cookie-consent';
import * as cart from './cart';
import * as cookieConsent from './cookie-consent';
import * as boxes from './boxes';
import * as giftCards from './giftCards';

export default function* root() {
  yield all([
    takeLatest(GET_BOXES_REQUEST, boxes.getBoxes),
    takeLatest(GET_GIFT_CARDS_REQUEST, giftCards.getGiftCards),

    takeLatest(GET_CART_REQUEST, cart.getCart),
    takeLatest(RESET_CART, cart.resetCart),
    takeLatest(ADD_TO_CART_REQUEST, cart.addToCart),
    takeLatest(GO_TO_CHECKOUT, cart.goToCheckout),

    takeLatest(getConsentRequestToken, cookieConsent.get),
    takeLatest(setConsentRequestToken, cookieConsent.set),
  ]);
}
