import process from 'process';
import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import cookies from 'js-cookie';
import styles from '../../styles/Layout.module.css';
import Navigation from '../Navigation';
import {
  type PopUpData,
  type CookieConsentDTO,
  type FooterDTO,
  type Locale,
  type NavbarDTO,
  type NewsletterFormDTO,
} from '../../lib/types';
import { pageVisit } from '../../store/actions/events';
import { getCartRequest } from '../../store/actions/cart';
import { getBoxesRequest } from '../../store/actions/boxes';
import { getGiftCardsRequest } from '../../store/actions/giftCards';
import { CookieConsent } from '../CookieConsent';
import { Analytics } from '../Analytics';
import { CirculySetup } from '../Circuly';
import { PopUp } from '../PopUp';
import { type RootState } from '../../store';
import Reviews from '../ReviewsCarousel';
import Footer from '../Footer';
import { useNewsletterForm } from '../NewsletterSubscribe';

export function Layout(props: {
  readonly loginUrl: string;
  readonly children: React.ReactNode;
  readonly footer: FooterDTO;
  readonly navbar: NavbarDTO;
  readonly newsletter: NewsletterFormDTO;
  readonly cookieConsent: CookieConsentDTO;
  readonly statusCode: number;
  readonly popUp: PopUpData;
}) {
  const dispatch = useDispatch();
  const router = useRouter();

  const selectCookieConsent = (state: RootState) => state.cookieConsent;
  const selectCookieConsentMemoized = createSelector(
    selectCookieConsent,
    (cookieConsent) => cookieConsent
  );
  const cookieConsent = useSelector(selectCookieConsentMemoized);
  const [showPopUp, setShowPopUp] = useState<boolean>(false);

  useEffect(() => {
    dispatch(pageVisit({ url: router.asPath, statusCode: props.statusCode }));
  }, [router, props.statusCode, dispatch]);

  useEffect(() => {
    dispatch(getCartRequest());
    dispatch(getBoxesRequest(router.locale as Locale));
    dispatch(getGiftCardsRequest(router.locale as Locale));
  }, [router.locale, dispatch]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (!cookies.get('popup-subscribed') && !cookies.get('popup-set')) {
        setShowPopUp(true);
        cookies.set('popup-set', 'Activated', {
          expires: 2,
        });
      }
    }, 10 * 1000);
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  const subscribeProps = useNewsletterForm();

  const renderPopUp = () => {
    const { subscribe, status } = subscribeProps;
    if (!props.popUp?.title) {
      setShowPopUp(false);
    }

    const popUpContent = {
      title: props.popUp?.title,
      subtitle: props.popUp?.subtitle,
      extraInformation: props.popUp?.extraInformation,
      buttonText: props.popUp?.buttonText,
      placeholder: props.popUp?.placeholder,
      invalidEmailErrorMessage: props.popUp?.invalidEmailErrorMessage,
      errorMessage: props.popUp?.errorMessage,
      successTitle: props.popUp?.successTitle,
      successDescription: props.popUp?.successDescription,
    };
    return (
      showPopUp && (
        <PopUp
          content={popUpContent}
          backgroundColor={props.popUp?.backgroundColor}
          backgroundImage={props.popUp?.backgroundImage}
          decoratorImage={props.popUp?.decoratorImage}
          status={status}
          onValidated={(formData) => {
            try {
              subscribe(formData);
              return true;
            } catch {
              return false;
            }
          }}
          onClosed={() => {
            setShowPopUp(false);
          }}
        />
      )
    );
  };

  return (
    <>
      <div className={showPopUp ? styles.blur : ''}>
        {cookieConsent?.data?.accepted && (
          <Analytics
            gaMeasurementId={process.env.NEXT_PUBLIC_ANALYTICS_ID ?? ''}
            adwordsId={process.env.NEXT_PUBLIC_ADWORDS_ID ?? ''}
            awinAdvertiserId={process.env.NEXT_PUBLIC_AWIN_ADVERTISER_ID ?? ''}
          />
        )}
        <div className={styles.container}>
          <CirculySetup />

          <Navigation
            announcement={props.navbar.announcement}
            logo={props.navbar.logo}
            items={props.navbar.items}
            loginUrl={props.loginUrl}
          />

          <div
            style={{
              marginTop: props.navbar.announcement ? '6.5rem' : '4.5rem',
            }}
          >
            {props.children}
          </div>
        </div>
        <Reviews />
        <Footer
          logo={props.footer.logo}
          columns={props.footer.columns}
          subfooter={props.footer.subfooter}
          socialLinks={props.footer.socialLinks}
          socialLinksTitle={props.footer.socialLinksTitle}
          emailAddress={props.footer.emailAddress}
          sponsorLogo={props.footer.sponsorLogo}
          sponsorLogoText={props.footer.sponsorLogoText}
          sponsorLogoLink={props.footer.sponsorLogoLink}
          newsletter={props.newsletter}
          associatedCompaniesTitle={props.footer.associatedCompaniesTitle}
        />
        <CookieConsent
          cookieAnnouncement={props.cookieConsent.cookieAnnouncement}
          policyLinkText={props.cookieConsent.policyLinkText}
          link={props.cookieConsent.link}
          declineButtonText={props.cookieConsent.declineButtonText}
          allowButtonText={props.cookieConsent.allowButtonText}
        />
      </div>
      {renderPopUp()}
    </>
  );
}
