import axios from "axios"
import { put, call } from "redux-saga/effects"
import { CmsGiftCardDto } from "../lib/types"
import { getGiftCardsFailed, getGiftCardsRequest, getGiftCardsSuccess } from "../store/actions/giftCards"
import { datadogRum } from '@datadog/browser-rum';

export function* getGiftCards(action: ReturnType<typeof getGiftCardsRequest>) {
  try {
    const giftCards: { data: CmsGiftCardDto[] } = yield call(axios.get, `/api/gift-cards?locale=${action.payload.locale}`)
    yield put(getGiftCardsSuccess(giftCards.data || []))
  } catch (error) {
    datadogRum.addError(error)
    yield put(getGiftCardsFailed())
  }
}
