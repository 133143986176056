import { call, put } from 'redux-saga/effects';
import * as cookieConsent from '../lib/utils/cookieConsent';
import {
  getConsentSuccess,
  setConsentSuccess,
} from '../store/actions/cookie-consent';

export function* set(action) {
  yield call(cookieConsent.set, action.payload.accepted);
  yield put(setConsentSuccess(action.payload));
}

export function* get() {
  const consent = yield call(cookieConsent.get);
  yield put(getConsentSuccess(consent));
}
