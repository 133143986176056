import { type SubscriptionVariant } from '../../lib/types';

export const RESET_CART = 'CART/reset';
export const GET_CART_REQUEST = 'CART/getRequest';
export const GET_CART_SUCCESS = 'CART/getSuccess';
export const GET_CART_FAILED = 'CART/getFailed';

export const ADD_TO_CART_REQUEST = 'CART/addItemRequest';
export const ADD_TO_CART_SUCCESS = 'CART/addItemSuccess';
export const ADD_TO_CART_FAILED = 'CART/addItemFailed';

export const CHANGE_ITEM_QUANTITY_REQUEST = 'CART/changeItemQuantityRequest';
export const CHANGE_ITEM_QUANTITY_SUCCESS = 'CART/changeItemQuantitySuccess';
export const CHANGE_ITEM_QUANTITY_FAILED = 'CART/changeItemQuantityFailed';

export const START_CHECKOUT = 'CART/startCheckout';
export const CHECKOUT_SUCCESS = 'CART/checkoutSuccess';
export const CHECKOUT_FAILED = 'CART/checkoutFailed';

export const GO_TO_CHECKOUT = 'CART/goToCheckout';

export const getResetCartRequest = () => ({
  type: RESET_CART,
  payload: {},
});

export const getCartRequest = () => ({
  type: GET_CART_REQUEST,
  payload: {},
});

export const getCartSuccess = () => ({
  type: GET_CART_SUCCESS,
});

export const getCartFailed = (payload) => ({
  type: GET_CART_FAILED,
  payload,
});

export const addToCartRequest = (payload: {
  id: string;
  subscription?: SubscriptionVariant;
  redirect?: boolean;
}) => ({
  type: ADD_TO_CART_REQUEST,
  payload,
});

export const addToCartSuccess = (payload) => ({
  type: ADD_TO_CART_SUCCESS,
  payload,
});

export const addToCartFailed = () => ({
  type: ADD_TO_CART_FAILED,
  payload: {},
});

export const startCheckout = () => ({
  type: START_CHECKOUT,
  payload: {},
});

export const checkoutSuccess = () => ({
  type: CHECKOUT_SUCCESS,
  payload: {},
});

export const checkoutFailed = () => ({
  type: CHECKOUT_FAILED,
  payload: {},
});

export const goToCheckout = () => ({
  type: GO_TO_CHECKOUT,
  payload: {},
});
