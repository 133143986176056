import React, { useMemo } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import Link from 'next/link';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import styles from '../../styles/CartDropdown.module.css';
import stylesCartItem from '../../styles/CartItem.module.css';
import { useAppContext } from '../../context/shared';
import { type CirculyCartItem, type SubscriptionVariant } from '../../lib/types';
import { clickedCTA } from '../../store/actions/events';
import { ctaClickSources } from '../../lib/constants';
import CartDropdownEmptyState from './CartDropdownEmptyState';
import CartItem from './CartItem';

function CartDropdown(props: {
  readonly visible: boolean;
  readonly handleMouseEnter: any;
  readonly handleMouseLeave: any;
  readonly cart: any;
  readonly boxes: any;
}) {
  const { localization } = useAppContext();

  const dispatch = useDispatch();
  const router = useRouter();

  const isPrepaidSubscription = (subscription: SubscriptionVariant) => {
    return (
      subscription &&
      subscription !== 'monthly' &&
      !subscription.includes('Monthly')
    );
  };

  const renderCartItem = (item: CirculyCartItem) => {
    if (props.boxes.loading || !props.boxes.ready || !props.boxes.data)
      return null;

    const box = props.boxes.data.find((box) =>
      Object.values(box.variantsJson).find(
        (variant: any) => variant.id == item.sku
      )
    );
    const selectedSubscription = Object.keys(box?.variantsJson || {}).find(
      (key: any) => box?.variantsJson?.[key]?.id == item.sku
    );

    if (!box || !selectedSubscription) {
      return (
        <Row key={item.id}>
          <Col className="order-3" sm={12}>
            <div className="d-flex gap-3 align-items-center">
              <div className="d-flex align-items-center gap-3">
                <div
                  className={stylesCartItem.thumbnail}
                  style={{ backgroundImage: `url(${item.thumbnail})` }}
                />
                <h6 className="mb-0">{item.name}</h6>
              </div>
            </div>
          </Col>
        </Row>
      );
    }

    return (
      <CartItem
        key={item.id}
        compact
        boxId={box?.id}
        alternatives={[]}
        name={item.name}
        image={item.thumbnail}
        subscription={
          item.subscription && (selectedSubscription as SubscriptionVariant)
        } // TODO
        price={item.quantity * Number.parseFloat(item.price)}
        tag={box?.ageGroup?.displayName}
        disabled={props.cart.loading}
        prepaidSubscriptions={isPrepaidSubscription(
          selectedSubscription as SubscriptionVariant
        )}
        onPrepaymentToggle={() => () => {}}
      />
    );
  };

  const handleCTAClick = (isCartEmpty: boolean) => {
    const payload = {
      name: isCartEmpty
        ? ctaClickSources.cartDropdown.emptyCart.name
        : ctaClickSources.cartDropdown.nonEmptyCart.name,
      url: router.asPath,
    };
    dispatch(clickedCTA(payload));
  };

  const [isEmpty, isLoading] = useMemo(() => {
    return [props?.cart?.data?.items?.length === 0, props?.cart?.loading];
  }, [props.cart]);

  return (
    <div
      className={[
        styles.container,
        props.visible ? 'd-none d-sm-block' : 'd-none',
        'p-3',
      ].join(' ')}
      onMouseEnter={() => props.handleMouseEnter()}
      onMouseLeave={() => props.handleMouseLeave()}
    >
      <h4 className="text-center pb-3">{localization.cart_dropdown_title}</h4>
      <div
        className={`d-flex flex-column gap-2 ${styles.cartItems} ${isLoading ? styles.loading : ''}`}
      >
        {isEmpty && <CartDropdownEmptyState />}
        {!isEmpty && (
          <>
            {props.cart?.data?.items?.map((item: CirculyCartItem) =>
              renderCartItem(item)
            )}
          </>
        )}
      </div>
      <Link href={isEmpty ? '/our-boxes' : '/cart'}>
        <Button
          size="lg"
          className={`w-100 mt-3 ${isLoading ? styles.loading : ''}`}
          onClick={() => {
            handleCTAClick(props.cart.data?.items.length === 0);
          }}
        >
          {isEmpty
            ? localization.cart_dropdown_empty_btn
            : localization.cart_dropdown_btn}
        </Button>
      </Link>
    </div>
  );
}

export default CartDropdown;
