import qs from "qs";

const localization = () => {
  const query = qs.stringify({
    pagination: {
      start: 0,
      limit: 100
    }
  })
  return '/global-localizations?' + query
}

export default localization