import { Locale } from "../types"

export const formatAmount = (amount: string | number, locale?: Locale) => {
  const parsedAmount = parseFloat(amount.toString())
  const parts = [
    Intl.NumberFormat("de", { currency: "EUR" }).format(parsedAmount),
    "€"
  ]
  if (locale === "en") parts.reverse()
  return parts.join("")
}