import axios from "axios"
import React from "react"
import { Button, Col, Container, Row } from "react-bootstrap"

class ErrorBoundary extends React.Component<any, any> {
  constructor(props: React.PropsWithChildren) {
    super(props)
    this.state = { hasError: false }
  }
  static getDerivedStateFromError(error) {
    return { hasError: true }
  }
  async componentDidCatch(error, errorInfo) {
    await axios.post("/api/errors", { error, errorInfo })
    console.error({ error, errorInfo })
  }
  render() {
    if (this.state.hasError) {
      return (
        <Container className="vh-100">
          <Row className="h-100">
            <Col sm="6" className="h-100 d-flex align-items-center mx-auto">
              <div className="w-100 text-center">
                <h2 className="mb-3">Oops, something went wrong!</h2>
                <Button onClick={() => window.location.reload()}>
                  Try again?
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary