import process from 'process';
import axios from 'axios';
import cookies from 'js-cookie';
import { type CirculyCartCandidate, type SubscriptionVariant } from '../types';

const cartApi = axios.create({
  // eslint-disable-next-line @typescript-eslint/naming-convention
  baseURL:
    String(process.env.NEXT_PUBLIC_CIRCULY_API) +
    process.env.NEXT_PUBLIC_CIRCULY_API_KEY +
    '/cart',
  timeout: 5000,
});

const cartCookie = '_circuly_cart';

export const deleteCartCookie = () => {
  cookies.remove(cartCookie);
};

export const setCartId = (id: string) => {
  cookies.set(cartCookie, id, {
    expires: 2,
    sameSite: 'strict',
    secure: true,
  });
};

export const getCartId = () => {
  return cookies.get(cartCookie);
};

export const getCart = async () => {
  const cartId = getCartId();
  // FIXME: this is stupid
  if (!cartId) {
    return {
      already_paid: false, // eslint-disable-line @typescript-eslint/naming-convention
    };
  }

  const cart = await cartApi.get(`/${cartId}`);
  return cart.data; // eslint-disable-line @typescript-eslint/no-unsafe-return
};

export const addToCart = async (item: CirculyCartCandidate) => {
  const addSubscriptionEvent = new CustomEvent('circuly-add-to-cart', {
    detail: {
      ...item,
    },
  });
  dispatchEvent(addSubscriptionEvent);
};

export const createCartCandidate = (
  id: string,
  subscription?: SubscriptionVariant
): CirculyCartCandidate => {
  if (!subscription) {
    return {
      sku: id,
      quantity: 1,
      subscription: false,
      subscription_start: null, // eslint-disable-line @typescript-eslint/naming-convention
      subscription_end: null, // eslint-disable-line @typescript-eslint/naming-convention
      subscription_duration: null, // eslint-disable-line @typescript-eslint/naming-convention
      subscription_period: null, // eslint-disable-line @typescript-eslint/naming-convention
      subscription_interval: 0, // eslint-disable-line @typescript-eslint/naming-convention
    };
  }

  // Specifies the subscription length.
  const durations = {
    monthly: 1,
    biannual: 6,
    annual: 12,
    biannualMonthly: 6,
    annualMonthly: 12,
  };

  // Specifies the prepaid length.
  const intervals = {
    monthly: 1,
    biannual: 6,
    annual: 12,
    biannualMonthly: 1,
    annualMonthly: 1,
  };

  return {
    sku: id,
    quantity: 1,
    subscription: true,
    subscription_start: null, // eslint-disable-line @typescript-eslint/naming-convention
    subscription_end: null, // eslint-disable-line @typescript-eslint/naming-convention
    subscription_duration: durations[subscription], // eslint-disable-line @typescript-eslint/naming-convention
    subscription_period: 'monthly', // eslint-disable-line @typescript-eslint/naming-convention
    subscription_interval: intervals[subscription], // eslint-disable-line @typescript-eslint/naming-convention
  };
};
