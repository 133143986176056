import qs from "qs";
import { CategoryDTO } from "../../types";

const relatedBlogPosts = (categories: CategoryDTO[]) => {
  const query = qs.stringify({
    populate: [
      'mainImage',
      'author.image',
      'blogPostCategories'
    ],
    pagination: {
      pageSize: 3
    },
    filters: {
      blogPostCategories: {
        id: { $in: categories.map(category => category.id) }
      }
    }
  })
  return '/blog-posts?' + query
}

export default relatedBlogPosts