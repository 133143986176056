import qs from "qs";

const ageGroups = () => {
  const query = qs.stringify({
    sort: [
      'minMonths:asc'
    ]
  })
  return '/age-groups?' + query
}

export default ageGroups