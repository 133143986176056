import Script from 'next/script';
import styles from '../../styles/Badge.module.css';
import { useEffect, useState } from 'react';
import { useAppContext } from '../../context/shared';

const BADGE_ID = 'badge-160';

export default function Badge() {
  const [reviewsBadgeScriptLoaded, setReviewsBadgeScriptLoaded] =
    useState(false);
  const { locale } = useAppContext();

  useEffect(() => {
    if (reviewsBadgeScriptLoaded && window.reviewsBadgeRibbon) {
      const el = document.getElementById(BADGE_ID);

      if (el) {
        el.innerHTML = '';
      }

      window.reviewsBadgeModern(BADGE_ID, {
        store: 'tribu-box.com',
        lang: locale,
        primaryClr: '#72D4AA',
        starsClr: '#FEDA4A',
        translateReviews: ' Bewertungen',
        translateAverage: ' Durchschnitt',
        translateDecimal: '.',
      });
    }
  }, [locale, reviewsBadgeScriptLoaded]);

  return (
    <>
      <Script
        src="https://widget.reviews.io/badge-modern/dist.js"
        onLoad={() => {
          setReviewsBadgeScriptLoaded(true);
        }}
      ></Script>
      <div id={BADGE_ID} className={styles.badge}></div>
    </>
  );
}
