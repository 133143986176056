import {
  type GiftCardData,
  type BoxData,
  type SubscriptionVariant,
} from '../types';

enum ConvergeEvents {
  PageLoaded = '$page_load',
  ViewedProduct = 'Viewed Product',
  AddedToCart = 'Added To Cart',
  SubscribedToNewsletter = 'Subscribed To Newsletter',
  // Custom events
  ViewedExperiment = 'Viewed Experiment',
}

type ConvergeProduct = {
  product_id: string;
  variant_id?: string;
  sku: string;
  name: string;
  variant_name?: string;
  price: number;
  currency: 'EUR' | 'USD';
  vendor?: string;
};

declare global {
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/consistent-type-definitions
  interface Window {
    cvg?: (a: {
      method: 'track';
      eventName: string;
      properties?: Record<string, any>;
      profileProperties?: Record<string, any>;
      aliases?: string[];
    }) => void;
  }
}

class ConvergeTracking {
  public trackPageLoaded() {
    this.trackAny(ConvergeEvents.PageLoaded);
  }

  public trackViewedProduct(product: BoxData) {
    this.trackAny(
      ConvergeEvents.ViewedProduct,
      this.mapBoxDataToConvergeProduct(product)
    );
  }

  public trackViewedProductGiftCard(giftCard: GiftCardData) {
    this.trackAny(
      ConvergeEvents.ViewedProduct,
      this.mapGiftCardDataToConvergeProduct(
        giftCard,
        Object.keys(giftCard.variantsJson)[0]
      )
    );
  }

  public trackAddedToCart(product: BoxData, variant: SubscriptionVariant) {
    this.trackAny(
      ConvergeEvents.AddedToCart,
      this.mapBoxDataToConvergeProduct(product, variant)
    );
  }

  public trackAddedToCartGiftCard(giftCard: GiftCardData, variant: string) {
    this.trackAny(
      ConvergeEvents.AddedToCart,
      this.mapGiftCardDataToConvergeProduct(giftCard, variant)
    );
  }

  public trackSubscribedToNewsletter(email: string) {
    this.trackAny(
      ConvergeEvents.SubscribedToNewsletter,
      {},
      { $email: email },
      [`urn:email:${email}`]
    );
  }

  public trackViewedExperiment(experimentId: string, variationId: string) {
    this.trackAny(ConvergeEvents.ViewedExperiment, {
      variation_id: variationId, // eslint-disable-line @typescript-eslint/naming-convention
      experiment_id: experimentId, // eslint-disable-line @typescript-eslint/naming-convention
    });
  }

  private mapBoxDataToConvergeProduct(
    box: BoxData,
    variant: SubscriptionVariant = 'annual'
  ): ConvergeProduct {
    return {
      product_id: box.circulyId, // eslint-disable-line @typescript-eslint/naming-convention
      variant_id: box.variantsJson[variant].id, // eslint-disable-line @typescript-eslint/naming-convention
      sku: box.variantsJson[variant].sku,
      name: box.shopifyTitle,
      variant_name: variant, // eslint-disable-line @typescript-eslint/naming-convention
      price: Number.parseFloat(box.variantsJson[variant].price),
      currency: 'EUR',
    };
  }

  private mapGiftCardDataToConvergeProduct(
    giftCard: GiftCardData,
    variant: string
  ): ConvergeProduct {
    return {
      product_id: giftCard.circulyId, // eslint-disable-line @typescript-eslint/naming-convention
      variant_id: giftCard.variantsJson[variant].id, // eslint-disable-line @typescript-eslint/naming-convention
      sku: giftCard.variantsJson[variant].sku,
      name: giftCard.shopifyTitle,
      variant_name: variant, // eslint-disable-line @typescript-eslint/naming-convention
      price: Number.parseFloat(giftCard.variantsJson[variant].price),
      currency: 'EUR',
    };
  }

  private trackAny(
    event: ConvergeEvents,
    properties?: Record<string, any>,
    profileProperties?: Record<string, any>,
    aliases?: string[]
  ) {
    if (window.cvg) {
      window.cvg({
        method: 'track',
        eventName: event,
        properties,
        profileProperties,
        aliases,
      });
    }
  }
}

export const convergeTracking = new ConvergeTracking();
