import { useCallback, useState } from 'react';
import {
  NewsletterFormFields,
  NewsletterStatus,
} from '../../lib/types/newsletter';
import axios from 'axios';

export interface UseNewsletterFormReturnType {
  subscribe: (input: NewsletterFormFields) => void;
  status: NewsletterStatus;
}
export function useNewsletterForm(): UseNewsletterFormReturnType {
  const [status, setStatus] = useState<NewsletterStatus>(
    NewsletterStatus.initial
  );

  const subscribe = useCallback(async (input: NewsletterFormFields) => {
    setStatus(NewsletterStatus.sending);
    try {
      await axios.post('/api/newsletter', input);
      setStatus(NewsletterStatus.success);
    } catch (e) {
      console.error(e);
      setStatus(NewsletterStatus.error);
    }
  }, []);
  return { subscribe, status };
}
