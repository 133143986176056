import cookies from 'js-cookie';
import { call, put, select, take } from 'redux-saga/effects';
import { datadogRum } from '@datadog/browser-rum';
import * as circuly from '../lib/utils/circuly';
import {
  addToCartFailed,
  addToCartRequest,
  addToCartSuccess,
  checkoutFailed,
  checkoutSuccess,
  getCartSuccess,
  getResetCartRequest,
  startCheckout,
} from '../store/actions/cart';
import { addedToCart } from '../store/actions/events';
import { GET_BOXES_SUCCESS } from '../store/actions/boxes';
import { GET_GIFT_CARDS_SUCCESS } from '../store/actions/giftCards';

const cartCookie = '_local_cart';

export function* getCart(action) {
  // Recover cart from cookie
  const recoverCart = cookies.get(cartCookie);
  if (recoverCart) {
    yield put(getResetCartRequest());
    const { id, subscription } = JSON.parse(recoverCart);
    const circulyCartData = yield call(circuly.getCart);

    // If the cart is already paid on circuly site, let's ignore this recovery
    if (circulyCartData.already_paid) {
      yield put(getResetCartRequest());
    } else {
      yield put(addToCartRequest({ id, subscription, redirect: false }));
      // TODO: should also call a success action, but this one overwrites currently, needed?
    }
  }

  yield put(getCartSuccess());
}

export function* addToCart(action) {
  const { id: variantId, subscription, redirect = true } = action.payload;

  // Giftcard ... this should be better distinguished
  if (subscription) {
    const { boxes } = yield select((state) => state);
    if (!boxes.ready) {
      // Wait for boxes to be ready before continuing
      yield take(GET_BOXES_SUCCESS);
    }

    const box = yield select((state) =>
      state.boxes.data.find((box) =>
        Object.values(box.variantsJson).find(
          (value: any) => value.id == variantId
        )
      )
    );

    if (!box) {
      yield put(addToCartFailed());
      return;
    }

    yield put(
      addToCartSuccess({
        id: box.id,
        sku: box.variantsJson[subscription].id,
        name: box.shopifyTitle,
        quantity: 1,
        price: box.variantsJson[subscription].price,
        subscription,
        tag: box.ageGroup.displayName,
        thumbnail: box.overviewImage,
        variantsJson: box.variantsJson,
        redirect,
      })
    );

    yield put(addedToCart({ boxTitle: box.shopifyTitle, subscription }));
  } else {
    const { giftCards: giftcards } = yield select((state) => state);
    if (!giftcards.ready) {
      // Wait for giftcards to be ready before continuing
      yield take(GET_GIFT_CARDS_SUCCESS);
    }

    const giftcard = yield select((state) =>
      state.giftCards.data.find((giftcard) =>
        Object.values(giftcard.variantsJson).find(
          (value: any) => value.id == variantId
        )
      )
    );

    if (!giftcard) {
      yield put(addToCartFailed());
      return;
    }

    // FIXME: type safety here
    const variant = Object.values(giftcard.variantsJson).find(
      (value: any) => value.id == variantId
    ) as any;
    yield put(
      addToCartSuccess({
        id: giftcard.id,
        sku: variantId,
        name: giftcard.shopifyTitle,
        quantity: 1,
        price: variant?.price,
        subscription: false,
        tag: giftcard.description,
        thumbnail: giftcard.shopifyImageUrl,
        variantsJson: giftcard,
        redirect,
      })
    );

    yield put(addedToCart({ boxTitle: giftcard.title }));
  }

  cookies.set(cartCookie, JSON.stringify(action.payload), {
    expires: 2,
    sameSite: 'strict',
    secure: true,
  });
}

export function* goToCheckout() {
  yield put(startCheckout());
  try {
    const {
      cart: {
        data: { items },
      },
    } = yield select((state) => ({ cart: state.cart }));
    const item = items[0];
    const candidate = yield call(
      circuly.createCartCandidate,
      item.sku,
      item.subscription
      );
    yield call(circuly.addToCart, candidate);
    yield put(checkoutSuccess());
  } catch (error) {
    datadogRum.addError(error);
    yield put(getResetCartRequest());
    yield put(checkoutFailed());
    yield call(circuly.deleteCartCookie);
  }
}

export function* resetCart() {
  yield call(circuly.deleteCartCookie);
  yield call(cookies.remove, cartCookie);
}
