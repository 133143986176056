import process from 'process';
import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { createRouterMiddleware } from 'connected-next-router';
import { HYDRATE } from 'next-redux-wrapper';
import rootSaga from '../sagas';
import createRootReducer from './reducers';

const routerMiddleware = createRouterMiddleware();

const sagaMiddleware = createSagaMiddleware();

const rootReducer = createRootReducer();

type ReducerType = typeof rootReducer;

const reducer: ReducerType = (state, action) => {
  if (action.type === HYDRATE) {
    const nextState = {
      ...state, // Use previous state
      ...action.payload, // Apply delta from hydration
    };
    if (typeof window !== 'undefined' && state?.router) {
      // Preserve router value on client side navigation
      nextState.router = state.router;
    }

    return nextState;
  }

  return rootReducer(state, action);
};

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    // eslint-disable-next-line unicorn/prefer-spread
    getDefaultMiddleware().concat(sagaMiddleware).concat(routerMiddleware),
  devTools: process.env.NODE_ENV !== 'production',
});

sagaMiddleware.run(rootSaga);

export type RootState = ReturnType<typeof store.getState>;

export default store;
