import styles from '../../styles/Footer.module.css';
import Link from 'next/link';
import { FooterProps } from '../../lib/types';
import { Col, Container, Row } from 'react-bootstrap';
import { useMemo } from 'react';
import { NewsletterForm } from '../NewsletterForm';
import DHL_LOGO from '../../public/images/dhl_logo.svg';
import DPD_LOGO from '../../public/images/dpd_logo.svg';
import TOP_LOGO from '../../public/images/top_logo.svg';
import Image from 'next/image';
import Badge from '../Badge';
import { useNewsletterForm } from '../NewsletterSubscribe';

const ASSOCIATED_COMPANIES = [DHL_LOGO, DPD_LOGO, TOP_LOGO];

const Footer = (props: FooterProps) => {
  const {
    logo,
    columns,
    socialLinks,
    subfooter,
    emailAddress,
    socialLinksTitle,
    sponsorLogo,
    sponsorLogoLink,
    sponsorLogoText,
    associatedCompaniesTitle,
    newsletter,
  } = props;
  const { subscribe, status } = useNewsletterForm();
  const gridSize = useMemo(
    () => (columns ? Math.floor(12 / (columns.length + 2)) : '12'),
    [columns]
  );

  if (
    !logo &&
    (!columns || columns.length === 0) &&
    (!socialLinks || socialLinks.length === 0) &&
    !emailAddress &&
    !socialLinksTitle
  )
    return <></>;
  return (
    <>
      <div className={styles.footer}>
        <Container className="py-5">
          <Row className="justify-content-center">
            <Col lg={gridSize} className="flex-grow-1 mb-4">
              <div
                className={[styles.logo, 'flex-grow-1 md:mb-5 mb-lg-0'].join(
                  ' '
                )}
              >
                <img src={logo} alt="logo" className="img-fluid" />
              </div>
              <NewsletterForm
                title={newsletter.title}
                subtitle={newsletter.subtitle}
                buttonText={newsletter.buttonText}
                placeholder={newsletter.placeholder}
                backgroundColor={newsletter.backgroundColor}
                backgroundImage={newsletter.backgroundImage}
                invalidEmailErrorMessage={newsletter.invalidEmailErrorMessage}
                errorMessage={props.newsletter.errorMessage}
                successTitle={props.newsletter.successTitle}
                successDescription={props.newsletter.successDescription}
                status={status}
                onValidated={(formData) => {
                  try {
                    subscribe(formData);
                    return true;
                  } catch {
                    return false;
                  }
                }}
              />
              <Badge />
            </Col>
            {columns &&
              columns.map((column, index) => (
                <Col
                  lg={gridSize}
                  key={column.title + '_' + index}
                  className="mb-4 mb-lg-0"
                >
                  <h5 className="pb-3 text-dark">{column.title}</h5>
                  <h6 className={'d-flex flex-column gap-3 fw-light'}>
                    {column.items.map((item, index) => (
                      <div
                        key={item.title + '_' + index}
                        className={styles.item}
                      >
                        <Link href={item.link}>{item.title}</Link>
                      </div>
                    ))}
                  </h6>
                </Col>
              ))}
            {((socialLinks && socialLinks.length > 0) || emailAddress) && (
              <Col lg={gridSize} className="d-flex flex-column">
                <h5 className="pb-3 text-dark">{socialLinksTitle}</h5>
                {socialLinks && (
                  <div className="d-flex gap-3">
                    {socialLinks.map((item, index) => (
                      <div
                        key={index}
                        className={[styles.item, 'pb-3'].join(' ')}
                      >
                        <a href={item.link} target="_blank" rel="noreferrer">
                          <img src={item.logo} alt="logo" />
                        </a>
                      </div>
                    ))}
                  </div>
                )}
                {emailAddress && (
                  <div className={[styles.items, 'mb-auto'].join(' ')}>
                    <h6 className={'fw-light'}>
                      <div className={styles.item}>
                        <a href={`mailto:${emailAddress}`}>{emailAddress}</a>
                      </div>
                    </h6>
                  </div>
                )}
                {associatedCompaniesTitle ? (
                  <div className="mt-3">
                    <h5 className="pb-3 text-dark">
                      {associatedCompaniesTitle}
                    </h5>
                    <div className="d-flex gap-2">
                      {ASSOCIATED_COMPANIES.map((href, index) => (
                        <Image key={index} src={href} alt="logo" />
                      ))}
                    </div>
                  </div>
                ) : null}
                {sponsorLogo ? (
                  <div className="pt-5 pe-5">
                    <p className="caption-small fw-light">{sponsorLogoText}</p>
                    <a
                      target="_blank"
                      href={sponsorLogoLink || '#'}
                      rel="noopener noreferrer"
                    >
                      <img
                        className="img-fluid pt-3 clickable"
                        src={sponsorLogo}
                        alt="sponsor logo"
                      />
                    </a>
                  </div>
                ) : (
                  ''
                )}
              </Col>
            )}
          </Row>
        </Container>
      </div>
      {subfooter && subfooter.columns && subfooter.columns.length > 0 ? (
        <div className={styles.subfooter}>
          <Container>
            <Row>
              {subfooter.columns?.map((column, index) => (
                <Col
                  lg={gridSize}
                  key={column.title + '_' + index}
                  className="mb-4 mb-lg-0"
                >
                  <h5 className="pb-3 text-dark">{column.title}</h5>
                  <h6 className={'d-flex flex-column gap-3 fw-light'}>
                    {column.items.map((item, index) => (
                      <div
                        key={item.title + '_' + index}
                        className={styles.item}
                      >
                        <Link href={item.link}>{item.title}</Link>
                      </div>
                    ))}
                  </h6>
                </Col>
              ))}
            </Row>
          </Container>
        </div>
      ) : null}
    </>
  );
};

export default Footer;
