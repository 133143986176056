/** @type {import('next').NextConfig} */
const nextConfig = {
  reactStrictMode: false,
  productionBrowserSourceMaps: true,
  i18n: {
    locales: ['en', 'de'],
    defaultLocale: 'de',
  },
  async rewrites() {
    return [
      {
        source: '/favicon.ico',
        destination: '/favicon.ico',
      },
    ];
  },
  generateBuildId: async () => {
    if (process.env.BUILD_ID) {
      return process.env.BUILD_ID;
    } else {
      return `${new Date().getTime()}`;
    }
  },
  images: {
    domains: [
      'tribu-cms-staging.s3.eu-north-1.amazonaws.com',
      'tribu-cms.s3.eu-central-1.amazonaws.com',
      'cdn.shopify.com',
    ],
  },
  env: {
    NEXT_PUBLIC_CUSTOMER_LOGIN_URL: process.env.NEXT_PUBLIC_CUSTOMER_LOGIN_URL,
    NEXT_PUBLIC_CHECKOUT_URL: process.env.NEXT_PUBLIC_CHECKOUT_URL,
    NEXT_PUBLIC_MIXPANEL_API: process.env.NEXT_PUBLIC_MIXPANEL_API,
    NEXT_PUBLIC_MIXPANEL_PROJECT_TOKEN:
      process.env.NEXT_PUBLIC_MIXPANEL_PROJECT_TOKEN,
    NEXT_PUBLIC_FIREBASE_CONFIG: process.env.NEXT_PUBLIC_FIREBASE_CONFIG,
    NEXT_PUBLIC_CIRCULY_API: process.env.NEXT_PUBLIC_CIRCULY_API,
    NEXT_PUBLIC_CIRCULY_API_KEY: process.env.NEXT_PUBLIC_CIRCULY_API_KEY,
    NEXT_PUBLIC_FACEBOOK_PIXEL_ID: process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID,
    NEXT_PUBLIC_ANALYTICS_ID: process.env.NEXT_PUBLIC_ANALYTICS_ID,
    NEXT_PUBLIC_ADWORDS_ID: process.env.NEXT_PUBLIC_ADWORDS_ID,
    NEXT_PUBLIC_AWIN_ADVERTISER_ID: process.env.NEXT_PUBLIC_AWIN_ADVERTISER_ID,
  },
};

module.exports = nextConfig;
