import {
  type CartStateItem,
  type Action,
  type CartStateData,
} from '../../lib/types';
import {
  ADD_TO_CART_FAILED,
  ADD_TO_CART_REQUEST,
  ADD_TO_CART_SUCCESS,
  CHECKOUT_FAILED,
  CHECKOUT_SUCCESS,
  GET_CART_FAILED,
  GET_CART_REQUEST,
  GET_CART_SUCCESS,
  RESET_CART,
  START_CHECKOUT,
} from '../actions/cart';
import { CHECKED_OUT } from '../actions/events';

type CartStateType = {
  loading: boolean;
  ready: boolean;
  error: boolean;
  data?: CartStateData;
};

const initial: CartStateType = {
  loading: false,
  ready: false,
  error: false,
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const cart = (state = initial, action: Action<any>): CartStateType => {
  switch (action.type) {
    case RESET_CART: {
      return {
        ...state,
        loading: false,
        ready: true,
        error: false,
        data: undefined,
      };
    }

    case GET_CART_REQUEST: {
      return { ...state, loading: true, error: false };
    }

    case GET_CART_SUCCESS: {
      return { ...state, loading: false, error: false, ready: true };
    }

    case GET_CART_FAILED: {
      return { ...state, loading: false, error: true };
    }

    case ADD_TO_CART_REQUEST: {
      return { ...state, loading: true, error: false };
    }

    case ADD_TO_CART_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        ready: true,
        data: {
          ...state.data,
          items: [action.payload as CartStateItem],
        },
      };
    }

    case ADD_TO_CART_FAILED: {
      return { ...state, loading: false, error: true };
    }

    case START_CHECKOUT: {
      return { ...state, loading: true };
    }

    case CHECKOUT_SUCCESS: {
      return { ...state, loading: false };
    }

    case CHECKOUT_FAILED: {
      return { ...state, loading: false, error: true };
    }

    case CHECKED_OUT: {
      return { ...state, loading: true, error: false };
    }

    default: {
      return state;
    }
  }
};

export default cart;
