import qs from "qs";

const subPage = (slug: string) => {
  const query = qs.stringify({
    filters: {
      slug: {
        $eq: slug
      }
    }
  })
  return '/subpages?' + query
}

export default subPage