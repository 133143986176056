import qs from 'qs';

export const ourBoxesPage = () => {
  const query = qs.stringify({
    populate: [
      'step1AgeGroup0to3Image',
      'step1AgeGroup3to6Image',
      'step2AgeGroup0to1Image',
      'step2AgeGroup1to2Image',
      'step2AgeGroup2to3Image',
      'step2AgeGroup3to6Image',
      'ageGroupPlus3Image',
      'step1AgeGroup0to3ImageAlt',
      'step1AgeGroup3to6ImageAlt',
      'step2AgeGroup0to1ImageAlt',
      'step2AgeGroup1to2ImageAlt',
      'step2AgeGroup2to3ImageAlt',
      'step2AgeGroup3to6ImageAlt',
      'ageGroupPlus3ImageAlt',
      'backgroundPattern',
      'subscriptions.backgroundImage',
    ],
  });
  return '/our-boxes-page?' + query;
};
