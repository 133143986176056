export enum NewsletterStatus {
  initial = 'initial',
  error = 'error',
  sending = 'sending',
  success = 'success',
}

export type NewsletterFormFields = {
  email: string;
};
