import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCookieBite } from '@fortawesome/free-solid-svg-icons';
import Link from 'next/link';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import {
  getConsentRequest,
  setConsentRequest,
} from '../../store/actions/cookie-consent';
import styles from '../../styles/CookieConsent.module.css';

export function CookieConsent(props: {
  cookieAnnouncement: string;
  policyLinkText: string;
  link: string;
  declineButtonText: string;
  allowButtonText: string;
}) {
  const dispatch = useDispatch();

  const selectCookieConsent = (state) => state.cookieConsent;
  const selectCookieConsentMemoized = createSelector(
    selectCookieConsent,
    (cookieConsent) => cookieConsent
  );
  const cookieConsent = useSelector(selectCookieConsentMemoized);

  useEffect(() => {
    dispatch(getConsentRequest());
  }, []);

  const handleAllowClick = () =>
    dispatch(setConsentRequest({ accepted: true }));
  const handleDeclineClick = () =>
    dispatch(setConsentRequest({ accepted: false }));

  return cookieConsent.ready && !cookieConsent.data ? (
    <Container
      fluid
      className={`px-3 py-2 position-fixed bottom-0 ${styles.container}`}
    >
      <Row className="align-items-center">
        <Col
          md="6"
          className="mb-3 mb-md-0 text-center text-md-start fw-normal"
        >
          <FontAwesomeIcon className="me-2" icon={faCookieBite} />
          {props.cookieAnnouncement && (
            <span className="me-2">{props.cookieAnnouncement}</span>
          )}
          {props.policyLinkText && (
            <Link href={props.link || ''}>
              <span className="text-decoration-underline clickable fw-bolder">
                {props.policyLinkText}
              </span>
            </Link>
          )}
        </Col>
        <Col md="6" className="text-center text-md-end d-flex d-md-inline">
          {props.declineButtonText && (
            <Button
              variant="outline-secondary"
              className="me-3"
              onClick={() => handleDeclineClick()}
            >
              {props.declineButtonText}
            </Button>
          )}
          {props.allowButtonText && (
            <Button
              className="flex-grow-1 flex-md-grow-0"
              onClick={() => handleAllowClick()}
            >
              {props.allowButtonText}
            </Button>
          )}
        </Col>
      </Row>
    </Container>
  ) : null;
}
