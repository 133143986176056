import qs from "qs";

const navbar = () => {
  const query = qs.stringify({
    populate: [
      'logo',
      'navbarItems'
    ]
  })
  return '/navbar?' + query
}

export default navbar