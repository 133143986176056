import qs from "qs";

const cartPage = () => {
  const query = qs.stringify({
    populate: [
      'reviews.titleImage',
      'orderBenefitsSection.orderBenefit.icon'
    ]
  })
  return '/cart-page?' + query
}

export default cartPage