import localization from './localization';
import howItWorksPage from './howItWorksPage';
import config from './config';
import homescreen from './homescreen';
import reviews from './reviews';
import quotes from './quotes';
import subscriptions from './subscriptions';
import footer from './footer';
import navbar from './navbar';
import newsletter from './newsletter';
import cookieConsent from './cookieConsent';
import aboutPage from './aboutPage';
import activityGuidePage from './activityGuidePage';
import cartPage from './cartPage';
import giftCardsPage from './giftCardsPage';
import giftCards from './giftCards';
import ourExpertsPage from './ourExpertsPage';
import subPage from './subPage';
import blogPost from './blogPost';
import ageGroups from './ageGroups';
import blogPostCategories from './blogPostCategories';
import authors from './authors';
import popularBlogPosts from './popularBlogPosts';
import featuredBlogPost from './featuredBlogPost';
import relatedBlogPosts from './relatedBlogPosts';
import { boxes } from './boxes';
import { ourBoxesPage } from './our-boxes-page';
import { ourBoxesItemPage } from './our-boxes-item-page';
import { popUp } from './pop-up';

const cmsEndpoints = {
  boxes,
  localization,
  howItWorksPage,
  config,
  homescreen,
  reviews,
  quotes,
  subscriptions,
  footer,
  navbar,
  newsletter,
  cookieConsent,
  aboutPage,
  activityGuidePage,
  cartPage,
  giftCardsPage,
  giftCards,
  ourBoxesPage,
  ourBoxesItemPage,
  ourExpertsPage,
  subPage,
  blogPost,
  ageGroups,
  blogPostCategories,
  authors,
  popularBlogPosts,
  featuredBlogPost,
  relatedBlogPosts,
  popUp,
};

export default cmsEndpoints;
