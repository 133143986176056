import qs from "qs";

const howItWorksPage = () => {
  const query = qs.stringify({
    populate: [
      'step.image',
      'backgroundPattern'
    ]
  })
  return '/how-it-works-page?' + query
}

export default howItWorksPage