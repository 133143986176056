import { createContext, useContext, useEffect, useState } from 'react';
import { LOCALIZATION_KEYS } from '../lib/constants';
import {
  ConfigContext,
  FeatureDTO,
  Locale,
  LocalizationContext,
  LocalizationDTO,
} from '../lib/types';
import nextConfig from '../next.config';

const AppContext = createContext<{
  localization: LocalizationContext;
  locale: Locale;
  config: ConfigContext;
  features: FeatureDTO;
  isMobile: boolean;
}>({
  localization: LOCALIZATION_KEYS,
  locale: nextConfig.i18n?.defaultLocale as any,
  isMobile: false,
  config: { metaTitle: '', metaDescription: '', metaImage: '' },
  features: {
    prepaidToggle: false,
  },
});

export function AppWrapper({
  children,
  localization,
  locale,
  config,
  features,
}) {
  const [isMobile, setIsMobile] = useState(false);
  const reducedLocalization = (
    Array.isArray(localization) ? localization : []
  ).reduce(
    (result, item) => Object.assign(result, { [item.key]: item.value }),
    {}
  );

  useEffect(() => {
    setIsMobile(window.innerWidth < 768);
    new ResizeObserver(() => {
      setIsMobile(window.innerWidth < 768);
    }).observe(document.body);
  }, []);

  return (
    <AppContext.Provider
      value={{
        localization: Object.keys(LOCALIZATION_KEYS).reduce(
          (result, key) =>
            Object.assign(result, {
              [key]: reducedLocalization[key] || key,
            }),
          LOCALIZATION_KEYS
        ),
        locale,
        config,
        features,
        isMobile,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

export function useAppContext() {
  return useContext(AppContext);
}
