import qs from "qs";

const quotes = () => {
  const query = qs.stringify({
    populate: [
      'logo'
    ]
  })
  return '/quotes?' + query
}

export default quotes