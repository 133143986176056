import qs from "qs";

const aboutPage = () => {
  const query = qs.stringify({
    populate: [
      'image'
    ]
  })
  return '/about-page?' + query
}

export default aboutPage