import { IMAGE_SIZES } from '../../constants';
import {
  type Locale,
  type CmsOrderBenefitDto,
  type OrderBenefitData,
} from '../../types';
import { imageFormatter } from '../image';

export const mapOrderBenefitData = (
  data: CmsOrderBenefitDto,
  locale: Locale
): OrderBenefitData => {
  const titleKey = `title_${locale}` as const;
  const descriptionKey = `description_${locale}` as const;

  return {
    title: data[titleKey],
    description: data[descriptionKey],
    icon: imageFormatter(data.icon, IMAGE_SIZES.medium),
  };
};
