import styles from '../../styles/Pill.module.css'

const Pill = (props: {
  content: string,
  id?: string,
  variant?: 'secondary' | 'secondary-bold' | 'secondary-transparent' | 'ageGroupSelector' | 'ageGroupSelectorActive' | null,
  onClick?: any,
  bgColor?: string
}) => {

  if (!props.content) return <></>

  return (
    <div onClick={(e) => props?.onClick && props.onClick(e)}
      id={props.id}
      className={[
        'pill caption',
        styles.container,
        (props.bgColor ? props.bgColor : 'bg-white'),
        (props.variant && styles[props.variant]),
        (props.onClick ? 'clickable' : '')
      ].join(' ')}
    >
      {props.content}
    </div>
  )
}

export default Pill