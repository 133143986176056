import axios from 'axios';
import { put, call } from 'redux-saga/effects';
import { type CmsBoxDto } from '../lib/types';
import {
  getBoxesFailed,
  type getBoxesRequest,
  getBoxesSuccess,
} from '../store/actions/boxes';

export function* getBoxes(action: ReturnType<typeof getBoxesRequest>) {
  try {
    const boxes: { data: CmsBoxDto[] } = yield call(
      axios.get,
      `/api/boxes?locale=${action.payload.locale}`
    );
    yield put(getBoxesSuccess(boxes.data || []));
  } catch (error) {
    console.log(error);
    yield put(getBoxesFailed());
  }
}
