import qs from "qs";

const featuredBlogPost = () => {
  const query = qs.stringify({
    populate: [
      'mainImage',
      'author.image',
      'blogPostCategories'
    ],
    sort: ["publishedAt:desc"],
    pagination: {
      start: 0,
      limit: 1
    }
  })
  return '/blog-posts?' + query
}

export default featuredBlogPost