import { Action, GiftCardData } from "../../lib/types"
import {
  GET_GIFT_CARDS_FAILED,
  GET_GIFT_CARDS_REQUEST,
  GET_GIFT_CARDS_SUCCESS,
} from "../actions/giftCards"

const initial: {
  loading: boolean
  ready: boolean
  error: boolean
  data: GiftCardData[]
} = {
  loading: false,
  ready: false,
  error: false,
  data: []
}

const giftCards = (state = initial, action: Action<any>) => {
  switch (action.type) {
    case GET_GIFT_CARDS_REQUEST:
      return Object.assign({}, state, {
        loading: true,
        error: false
      })
    case GET_GIFT_CARDS_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        error: false,
        ready: true,
        data: action.payload
      })
    case GET_GIFT_CARDS_FAILED:
      return Object.assign({}, state, {
        loading: false,
        error: true
      })

    default:
      return state
  }
}

export default giftCards
