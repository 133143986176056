import qs from "qs";

const giftCardsPage = () => {
  const query = qs.stringify({
    populate: [
      'image'
    ]
  })
  return '/gift-cards-page?' + query
}

export default giftCardsPage