import Script from 'next/script';
import styles from '../../styles/Reviews.module.css';
import { useAppContext } from '../../context/shared';
import { useEffect, useState } from 'react';
import { getConfig } from './config';

const CAROUSEL_ID = 'reviewsio-carousel-widget';

export default function Reviews() {
  const { locale } = useAppContext();
  const [reviewsIoScriptLoaded, setReviewsIoScriptLoaded] = useState(false);

  useEffect(() => {
    if (reviewsIoScriptLoaded && window.reviewsBadgeRibbon) {
      const el = document.getElementById(CAROUSEL_ID);

      if (el) {
        el.innerHTML = '';
      }

      new window.carouselInlineWidget(CAROUSEL_ID, getConfig(locale));
    }
  }, [locale, reviewsIoScriptLoaded]);

  return (
    <>
      <Script
        src="https://widget.reviews.io/carousel-inline-iframeless/dist.js?_t=2024082011"
        onLoad={() => {
          setReviewsIoScriptLoaded(true);
        }}
      ></Script>
      <link
        rel="stylesheet"
        href="https://assets.reviews.io/css/widgets/carousel-widget.css?_t=2024082011"
      />
      <link
        rel="stylesheet"
        href="https://assets.reviews.io/iconfont/reviewsio-icons/style.css?_t=2024082011"
      />
      <div className={styles.reviewsWrapper}>
        <div id={CAROUSEL_ID}></div>
      </div>
    </>
  );
}
