export const getConsentRequestToken = 'CONSENT/getRequest';
export const getConsentSuccessToken = 'CONSENT/getSuccess';

export const setConsentRequestToken = 'CONSENT/setRequest';
export const setConsentSuccessToken = 'CONSENT/setSuccess';

export const getConsentRequest = () => ({
  type: getConsentRequestToken,
  payload: {},
});

export const getConsentSuccess = (payload: { accepted: boolean }) => ({
  type: getConsentSuccessToken,
  payload,
});

export const setConsentRequest = (payload: { accepted: boolean }) => ({
  type: setConsentRequestToken,
  payload,
});

export const setConsentSuccess = (payload: { accepted: boolean }) => ({
  type: setConsentSuccessToken,
  payload,
});
