import styles from '../../styles/CartItem.module.css';
import { Button, Col, Row } from 'react-bootstrap';
import { useAppContext } from '../../context/shared';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import React, { useEffect, useMemo, useState } from 'react';
import Pill from '../Pill';
import { SubscriptionVariant } from '../../lib/types';
import { formatAmount } from '../../lib/utils/amount';
import SwitchSelector from 'react-switch-selector';
import { useMediaQuery } from 'react-responsive';

const CartItem = (props: {
  boxId: string;
  alternatives: any;
  name: string;
  image: string;
  subscription: SubscriptionVariant;
  price: number;
  tag?: string;
  disabled: boolean;
  compact?: boolean;
  onChangeClick?: () => any;
  onNodge?: (subscription: SubscriptionVariant) => any;
  onPrepaymentToggle: (
    boxId: string,
    subscription: SubscriptionVariant
  ) => () => void;
  prepaidSubscriptions: boolean;
}) => {
  const { localization, locale } = useAppContext();

  const divider = useMemo(() => {
    const dividers: Record<SubscriptionVariant, number> = {
      monthly: 1,
      biannual: 6,
      annual: 12,
      biannualMonthly: 6,
      annualMonthly: 12,
    };
    return dividers[props.subscription] || 1;
  }, [props.subscription]);

  const findCounterpartSubscriptionVariant = (
    subscription: SubscriptionVariant
  ) => {
    if (!props.alternatives) {
      return null;
    }

    const counterpartSubscriptions: Record<
      SubscriptionVariant,
      SubscriptionVariant
    > = {
      biannual: 'biannualMonthly',
      annual: 'annualMonthly',
      biannualMonthly: 'biannual',
      annualMonthly: 'annual',
      monthly: 'monthly',
    };

    return props.alternatives[counterpartSubscriptions[subscription]];
  };

  const counterPart = findCounterpartSubscriptionVariant(props.subscription);

  const yearlyPricePerMonth =
    props.price && counterPart
      ? props.price > counterPart.price
        ? props.price / divider
        : counterPart.price / divider
      : 0;
  const monthlyPrice =
    props.price && counterPart
      ? props.price > counterPart.price
        ? counterPart.price
        : props.price
      : 0;
  const yearlyDiscountPercentage =
    yearlyPricePerMonth > 0
      ? Math.abs(Math.round((1 - monthlyPrice / yearlyPricePerMonth) * 100))
      : 0;

  const options = [
    {
      label: (
        <div>
          {localization.cart_toggle_prepayment_label}
          <span style={{ color: 'green', fontWeight: 'bold' }}>
            &nbsp;{yearlyDiscountPercentage}% {localization.cart_save_prepaid}
          </span>
          <div className="caption-smallest" style={{ textAlign: 'center' }}>
            {yearlyPricePerMonth} {localization.cart_price_per_month}
          </div>
        </div>
      ),
      value: 'prepayment',
    },
    {
      label: (
        <div>
          {localization.cart_toggle_monthly_label}
          <div className="caption-smallest" style={{ textAlign: 'center' }}>
            {monthlyPrice} {localization.cart_price_per_month}
          </div>
        </div>
      ),
      value: 'monthly',
    },
  ];

  const initialSelectedIndex = props.prepaidSubscriptions ? 0 : 1;

  return (
    <div className={styles.container}>
      <Row>
        <Col
          className={`d-flex gap-4 align-items-center ${
            props.compact ? 'order-1' : 'order-sm-1'
          }`}
          sm={8}
        >
          <div>
            <div className="d-flex align-items-center gap-3">
              <h4>
                {
                  localization[
                    'cart_subscription_' +
                      props.subscription.replace('Monthly', '')
                  ]
                }
              </h4>
              <Button
                variant="secondary"
                className={props.compact ? 'd-none' : 'd-block'}
                onClick={() => props.onChangeClick && props.onChangeClick()}
              >
                {localization.cart_item_change_subscription_btn}
              </Button>
            </div>
          </div>
        </Col>

        {!props.compact &&
          ['biannual', 'annual'].some((item) =>
            props.subscription.includes(item)
          ) && (
            <Col className="order-2" sm={4}>
              <hr className="d-sm-none" />
              <div
                className="text-center flex-fill d-flex flex-grow-1 flex-column"
                style={{ height: 40 }}
              >
                <SwitchSelector
                  onChange={props.onPrepaymentToggle(
                    props.boxId,
                    props.subscription
                  )}
                  options={options}
                  initialSelectedIndex={initialSelectedIndex}
                  backgroundColor={'#DAE2E5'}
                  fontColor={'#000000'}
                  selectionIndicatorMargin={2}
                  selectedBackgroundColor={'#ffffff'}
                  selectedFontColor={'#000000'}
                />
              </div>
            </Col>
          )}

        <Col className={props.compact ? 'order-3' : 'order-sm-4'} sm={12}>
          <hr />

          <div className="d-flex gap-3 align-items-center">
            <FontAwesomeIcon icon={faArrowRight} className="me-2" />
            <div className="d-flex align-items-center gap-3">
              <div
                className={styles.thumbnail}
                style={{ backgroundImage: `url(${props.image})` }}
              />
              <h6 className="mb-0">{props.name}</h6>
              <div className="caption">
                {props.tag && <Pill content={props.tag} variant="secondary" />}
              </div>
            </div>
          </div>

          {props.subscription === 'monthly' && (
            <Button
              className={props.compact ? 'd-none' : 'd-block'}
              onClick={() => props.onNodge && props.onNodge('annual')}
            >
              {localization.cart_nodge}
            </Button>
          )}
        </Col>

        {props.compact && (
          <Col className={props.compact ? 'order-2' : 'order-sm-3'}>
            <div className="d-flex">
              <div className="text-center flex-fill d-flex flex-grow-1 flex-column w-100">
                {props.prepaidSubscriptions &&
                  ['biannual', 'annual'].some((item) =>
                    props.subscription.includes(item)
                  ) && (
                    <>
                      <h4 className="mb-0 align-items-center d-flex flex-fill justify-content-center">
                        <span>{formatAmount(props.price, locale)}</span>
                      </h4>
                      <div className="caption-smallest">
                        {divider} *{' '}
                        {formatAmount(props.price / divider, locale)}
                      </div>
                    </>
                  )}

                {!props.prepaidSubscriptions && (
                  <>
                    <h4 className="mb-0 align-items-center d-flex flex-fill justify-content-center">
                      <span>{formatAmount(props.price, locale)}</span>
                    </h4>
                    <div className="caption-smallest">
                      {localization.cart_price_per_month}
                    </div>
                  </>
                )}
              </div>
            </div>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default CartItem;
